/*
 * @Author: bowang
 * @Date: 2023-05-16 12:19:28
 * @Last Modified by: bowang
 * @Last Modified time: 2023-05-16 12:23:30
 */
/** 隐私政策 */
export const EASYCASH_IDN_REGISTER = 'EASYCASH_IDN_REGISTER';
/** 使用条款 */
export const EASYCASH_TERMS_OF_USE = 'EASYCASH_TERMS_OF_USE';
/** 授权书 */
export const ELECTRONIC_SIGNATURE_AUTHORIZATION = 'ELECTRONIC_SIGNATURE_AUTHORIZATION';
