var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vcode-input" }, [
    _c(
      "div",
      { staticClass: "v-code" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.code,
              expression: "code",
            },
          ],
          ref: "vcode",
          attrs: {
            id: "code-input",
            type: "tel",
            maxlength: _vm.codeLength,
            disabled: _vm.telDisabled,
          },
          domProps: { value: _vm.code },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.code = $event.target.value
              },
              function ($event) {
                _vm.code = _vm.code.replace(/[^\d]/g, "")
              },
            ],
            focus: _vm.handleFocus,
            blur: _vm.handleBlur,
          },
        }),
        _vm._v(" "),
        _vm._l(_vm.codeLength, function (item, index) {
          return _c("label", {
            key: "code-" + index,
            staticClass: "line",
            class: { empty: _vm.codeArr[index] === undefined },
            style: {
              color: _vm.codeArr[index] ? _vm.codeColor : _vm.dotColor,
              width: _vm.codeWidth,
            },
            attrs: { for: "code-input" },
            domProps: { textContent: _vm._s(_vm.codeArr[index]) },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }