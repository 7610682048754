<!--
 * @Author: zhaoyang
 * @Date: 2023-05-13 13:47:56
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-05-29 11:33:05
-->

<template>
    <van-overlay
        class="ec-overlay"
        v-bind="$attrs"
        @click="onClick"
        v-on="$listeners"
    >
        <div
            v-if="!fullScreen"
            class="wapper"
            :style="wapperStyle"
            @click.stop
        >
            <slot name="title">
                <div
                    v-if="title"
                    class="overlay-title"
                >
                    {{ title }}
                </div>
            </slot>
            <slot />
            <div
                v-if="showClose"
                class="close"
                :class="{'close-bottom': closeType === 'bottom'}"
                :style="closeStyle"
                @click="onClose"
            >
                <img
                    v-if="closeType === 'bottom'"
                    class="close-circle"
                    src="./img/delect-circle.svg"
                >
                <van-icon
                    v-else
                    name="cross"
                    size="14"
                    color="#999"
                />
            </div>
        </div>
        <div
            v-else
            class="full-screen"
        >
            <slot />
            <van-icon
                v-if="showClose"
                name="close"
                size="0.2rem"
                class="close-circle"
                color="#fff"
                @click="onClose"
            />
        </div>
    </van-overlay>
</template>

<script>
import {Icon} from 'vant';

export default {
    name: 'EcOverlay',

    components: {
        [Icon.name]: Icon
    },

    model: {
        prop: 'show',
        event: 'change'
    },

    props: {
        fullScreen: {
            type: Boolean,
            default: false
        },

        wapperStyle: {
            type: Object,
            default: () => ({})
        },

        closeStyle: {
            type: Object,
            default: () => ({})
        },

        title: {
            type: String,
            default: ''
        },

        showClose: {
            type: Boolean,
            default: true
        },

        closeType: {
            type: String,
            default: ''
        },

        closeOnClickOverlay: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onClick() {
            if (this.closeOnClickOverlay) {
                this.$emit('change');
            }
        },
        onClose() {
            this.$emit('change');
        }
    }
};
</script>

<style lang="scss" scoped>
.ec-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .wapper {
        box-sizing: border-box;
        border-radius: 0.08rem;
        position: relative;
        width: 80%;
        background-color: #fff;
        min-height: 0.3rem;

        .overlay-title {
            color: #333;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.18rem;
            text-align: center;
        }

        .close {
            box-sizing: border-box;
            padding: 0.05rem;
            position: absolute;
            top: 0;
            right: 0;

            .close-circle {
                width: 0.36rem;
                height: 0.36rem;
            }
        }

        .close-bottom {
            top: unset;
            right: 50%;
            bottom: -0.56rem;
            transform: translateX(50%);
        }
    }

    .full-screen {
        position: relative;

        .close-circle {
            position: absolute;
            top: -0.25rem;
            right: 0;
            width: 0.25rem;
            height: 0.25rem;
        }
    }
}
</style>
