/*
 * @Author: bowang
 * @Date: 2023-05-24 15:46:11
 * @Last Modified by: bowang
 * @Last Modified time: 2023-06-05 16:54:44
 */
export const mobileRule = [
    {
        validator: value => {
            const startCheck = value.startsWith('08') || value.startsWith('8');
            return value?.length >= 10 && value?.length <= 14 && startCheck;
        },
        message: 'mobilePhoneNoTip'
    }
];
export const registerLoginMobileRule = [
    {
        validator: value => {
            const startCheck = value.startsWith('08');
            return value?.length >= 11 && value?.length <= 14 && startCheck;
        },
        message: 'registerLoginPage.mobileTip'
    }
];
