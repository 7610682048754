<!-- @Author: bowang -->
<!-- @Date: 2023-01-10 14:46:17 -->
<!-- @Last Modified by:   bowang -->
<!-- @Last Modified time: 2023-01-10 14:46:17 -->

<template>
    <transition name="ec-modal-slide">
        <div
            v-if="visible"
            class="ec-modal"
            @click="handleClose"
        >
            <div
                class="ec-dialog"
                @click.stop.self.prevent
            >
                <div
                    v-if="title"
                    class="ec-dialog-title"
                >
                    <h3 class="title">
                        {{ title }}
                    </h3>
                </div>

                <div class="ec-dialog-body">
                    <slot />
                </div>

                <div
                    v-if="$slots.actions"
                    class="ec-dialog-actions"
                >
                    <slot name="actions" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script type="text/babel">
export default {
    name: 'EcModal',

    props: {
        insertBody: {
            type: Boolean,
            default: false
        },

        visible: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: ''
        }
    },

    mounted() {
        if (!this.insertBody) return;

        this.$nextTick(() => {
            const body = document.querySelector('body');
            body.appendChild(this.$el);
        });
    },

    methods: {
        handleClose() {
            const vm = this;
            vm.$emit('close');
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $xs: 576px;
    $easeOutFunction: cubic-bezier(0.78, 0.14, 0.15, 0.86);

    .ec-modal {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 40%);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2002;
    }

    .ec-dialog {
        display: flex;
        flex-direction: column;
        width: 40%;
        min-width: 3rem;
        background-color: #fff;
        box-shadow: 0 0.19rem 0.6rem rgba(0, 0, 0, 30%), 0 0.15rem 0.2rem rgba(0, 0, 0, 20%);

        .ec-dialog-title {
            padding: 0.2rem 0.2rem 0.1rem;

            .title {
                margin: 0;
            }
        }

        .ec-dialog-body {
            flex: 1;
            padding: 0 0.2rem 0.1rem;
            color: rgba(0, 0, 0, 60%);
        }

        .ec-dialog-actions {
            min-height: 0.4rem;
            padding: 0.1rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .btn {
                display: inline-block;
                box-sizing: border-box;
                text-align: center;
                cursor: pointer;
                padding: 0.1rem;
                font-size: 0.15rem;
                font-weight: 500;
                min-width: 0.8rem;

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }

    .ec-modal-slide-enter-active,
    .ec-modal-slide-leave-active {
        transition: opacity 0.36s $easeOutFunction;

        .ec-dialog {
            transition: transform 0.36s $easeOutFunction;
        }
    }

    .ec-modal-slide-enter,
    .ec-modal-slide-leave-to {
        opacity: 0%;

        .ec-dialog {
            transform: translate3d(0, -0.1rem, 0);
        }
    }
</style>

