/*
 * @Author: bowang
 * @Date: 2023-05-15 11:38:55
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-05-28 16:34:02
 */
import { registerLoginMobileRule } from 'easycash-h5/common/constant/rules';
import { numFormat } from 'easycash-h5/common/util/format';
export default {
    fieldDefs: [
        {
            label: ({ ctx }) => {
                return `registerLoginPage.${ctx.notifType === 'SMS' ? 'mobile' : 'whatsAppAccount'}`;
            },
            field: 'mobile',
            format: {
                format: numFormat
            },
            type: 'tel',
            rules: registerLoginMobileRule,
            useCleave: {
                blocks: [4, 4, 4, 2]
            },
            disabled({ ctx }) {
                return ctx.isJointLogin && ctx.jointMobileNumber;
            },
            required: true
        },
        {
            label: 'registerLoginPage.code',
            field: 'code',
            format: {
                format: numFormat
            },
            type: 'tel',
            maxlength: 6,
            required: true,
            rules: [
                {
                    validator: (value) => value?.length === 6,
                    message: 'registerLoginPage.codeTip'
                }
            ]
        }
    ]
};
