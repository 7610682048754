/*
 * @Author: fuzhongfeng
 * @Date: 2024-7-15 14:01:02
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-15 17:21:35
 */

import GeneralConfig from 'easycash-h5/common/resource/general-config';
import Operation from 'easycash-h5/common/resource/operation';

export default {
    created() {
        this.configValue = null;
        this.requestPermission = () => {};

        this.firebaseToken = null;
        this.CallTypeEnum = null;
    },
    methods: {
        async initFirebase() {
            try {
                const {data: {body: {
                    configs: [{configValue}]
                }}} = await GeneralConfig.get({params: {keys: 'app_config.h5_user_obtain_authorization_before_registration'}});
                this.configValue = configValue === 'TRUE';
            } catch (err) {
                // ignore
            }

            const {requestPermission, CallTypeEnum} = await import('src/common/util/firebase-app');
            this.requestPermission = requestPermission;
            this.CallTypeEnum = CallTypeEnum;
            if (this.configValue) {
                try {
                    this.firebaseToken = await this.requestPermission({
                        callType: this.CallTypeEnum.LOGIN_PAGE_INIT,
                        uploadClickEvent: this.uploadClickEvent,
                    });
                    // 上报 firebaseToken
                    this.firebaseToken && Operation.saveFirebaseToken({
                        token: this.firebaseToken,
                    });
                } catch (e) {
                    // ignore
                }
            }
        },
        async loginAfterRequestPermission () {
            try {
                if (!this.configValue) {
                    this.firebaseToken = await this.requestPermission({
                        callType: this.CallTypeEnum.LOGIN_PAGE_LOGIN_AFTER,
                        uploadClickEvent: this.uploadClickEvent,
                    });
                }

                // 上报 firebaseToken
                this.firebaseToken && Operation.saveFirebaseToken({
                    token: this.firebaseToken,
                });
            } catch (e) {
                // ignore
            }
        },
    }
};
