var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-overlay",
    _vm._g(
      _vm._b(
        { staticClass: "ec-overlay", on: { click: _vm.onClick } },
        "van-overlay",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.fullScreen
        ? _c(
            "div",
            {
              staticClass: "wapper",
              style: _vm.wapperStyle,
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm._t("title", function () {
                return [
                  _vm.title
                    ? _c("div", { staticClass: "overlay-title" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.title) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _vm.showClose
                ? _c(
                    "div",
                    {
                      staticClass: "close",
                      class: { "close-bottom": _vm.closeType === "bottom" },
                      style: _vm.closeStyle,
                      on: { click: _vm.onClose },
                    },
                    [
                      _vm.closeType === "bottom"
                        ? _c("img", {
                            staticClass: "close-circle",
                            attrs: { src: require("./img/delect-circle.svg") },
                          })
                        : _c("van-icon", {
                            attrs: { name: "cross", size: "14", color: "#999" },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "full-screen" },
            [
              _vm._t("default"),
              _vm._v(" "),
              _vm.showClose
                ? _c("van-icon", {
                    staticClass: "close-circle",
                    attrs: { name: "close", size: "0.2rem", color: "#fff" },
                    on: { click: _vm.onClose },
                  })
                : _vm._e(),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }