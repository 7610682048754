/*
 * @Author: zhaoyang
 * @Date: 2024-03-22 13:28:05
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-10 17:33:54
 */
import { assign, customGet, customPost } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    getStaticTextByKey: customGet('/api/operation/staticText/getByKey'),
    saveFirebaseToken: customPost('/api/operation/saveFirebaseToken'),
};
export default assign(api);
