var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "ec-modal-slide" } }, [
    _vm.visible
      ? _c("div", { staticClass: "ec-modal", on: { click: _vm.handleClose } }, [
          _c(
            "div",
            {
              staticClass: "ec-dialog",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.title
                ? _c("div", { staticClass: "ec-dialog-title" }, [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.title) +
                          "\n                "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ec-dialog-body" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm.$slots.actions
                ? _c(
                    "div",
                    { staticClass: "ec-dialog-actions" },
                    [_vm._t("actions")],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }