/*
 * @Author: zhaoyang
 * @Date: 2023-07-28 17:35:46
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-07-28 17:39:57
 */
export const methodsMap = {
    0: 'WHATSAPP',
    1: 'SMS'
};
