/*
 * @Author: zhaoyang
 * @Date: 2023-07-19 16:49:14
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-07-21 14:00:50
 */
/* global __GTAG_ID__ */
export const getClientId = () => {
    return new Promise((resolve, reject) => {
        try {
            gtag('get', __GTAG_ID__, 'client_id', resolve);
        }
        catch (error) {
            reject(error);
        }
    });
};
